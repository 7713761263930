<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <v-col sm="6" md="4" lg="4" xl="4">
          <FormButton
            text="Nova lista de compras"
            type="primario"
            full
            @click="onNew"
          />
        </v-col>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="name"
        :sortDesc="true"
        @click="onClick"
        search
        ref="datatable"
      >
        <template v-slot:idcliente="{ row }">
          <div v-if="row.idcliente">
            <span class="cliente">Cliente</span>
          </div>
          <div v-else-if="row.idsegmentocliente">
            <span class="segmento-cliente">Segmento de cliente</span>
          </div>
          <div v-else>
            <span class="todos">Todos</span>
          </div>
        </template>
        <template v-slot:ativo="{ col }">
          <Chip
            v-if="col"
            color="green"
            :small="true"
            text="Exibida para os clientes"
          />
          <Chip
            v-else
            color="red"
            :small="true"
            text="Oculta para os clientes"
          />
        </template>
        <template v-slot:datacriacao="{ col }">
          {{ col | formatDate() }}
        </template>
        <template v-slot:idlistacompras="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import { LISTA_COMPRAS_REMOVE } from '@/store/actions/listaCompras'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButton from '@/components/form/FormButton'
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import Chip from '@/components/Chip'
import { mapGetters } from 'vuex'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'ListaComprasTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    Chip,
    MarketingModules
  },
  data: () => ({
    headers: [
      { text: 'Nome', value: 'nome' },
      {
        text: 'Tipo',
        value: 'idcliente',
        align: 'center'
      },
      {
        text: 'Ativo',
        value: 'ativo',
        align: 'center'
      },
      {
        text: 'Data criação',
        value: 'datacriacao'
      },
      {
        text: 'Opções',
        value: 'idlistacompras',
        clickable: false
      }
    ],
    tableOptions: [
      {
        text: 'Deletar',
        value: 'delete'
      }
    ],
    modulosForm: [ModulesEnum.WEB_COMMERCE, ModulesEnum.COMMERCE_APP]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/shopping-list/${this.getFornecedorId}/data-table`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {},
  methods: {
    onClose() {
      this.$refs.datatable.reload()
    },
    onClick(item) {
      this.$router.push(`lista-compras/${item.idlistacompras}`)
    },
    onNew() {
      this.$router.push('lista-compras/novo')
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      this.$swal
        .fire({
          title: `Remover lista de compras? `,
          text: 'Essa ação é irreversível',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) this.delete(item)
          else return
        })
    },
    delete(item) {
      this.$store
        .dispatch(LISTA_COMPRAS_REMOVE, item.idlistacompras)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Lista de compras removido com sucesso'
          )
          this.$refs.datatable.reload()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.todos,
.cliente,
.segmento-cliente,
.pix {
  padding: 5px;
  margin: 3px;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
}
.todos {
  background-color: var(--secondary-color);
}
.cliente {
  background-color: var(--primary-color);
}
.segmento-cliente {
  background-color: var(--primary-2-color);
}
</style>
