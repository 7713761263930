var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('v-col',{attrs:{"sm":"6","md":"4","lg":"4","xl":"4"}},[_c('FormButton',{attrs:{"text":"Nova lista de compras","type":"primario","full":""},on:{"click":_vm.onNew}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"name","sortDesc":true,"search":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"idcliente",fn:function(ref){
var row = ref.row;
return [(row.idcliente)?_c('div',[_c('span',{staticClass:"cliente"},[_vm._v("Cliente")])]):(row.idsegmentocliente)?_c('div',[_c('span',{staticClass:"segmento-cliente"},[_vm._v("Segmento de cliente")])]):_c('div',[_c('span',{staticClass:"todos"},[_vm._v("Todos")])])]}},{key:"ativo",fn:function(ref){
var col = ref.col;
return [(col)?_c('Chip',{attrs:{"color":"green","small":true,"text":"Exibida para os clientes"}}):_c('Chip',{attrs:{"color":"red","small":true,"text":"Oculta para os clientes"}})]}},{key:"datacriacao",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(col))+" ")]}},{key:"idlistacompras",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }